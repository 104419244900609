import { Box, Container, Link, styled, Typography } from '@mui/material';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { Layout } from '../../components/Layout';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import Helmet from 'react-helmet';
import { FreeTrialCTA } from '@/components/FreeTrialCTA';
import { Widget } from '@/components/Widget';

const shortcodes = {
  Link,
  h1: (props) => <Typography variant="h1" gutterBottom sx={{ mt: 9, mb: 6 }} {...props} />,
  h2: (props) => <Typography variant="h2" gutterBottom sx={{ mt: 6, mb: 3 }} {...props} />,
  h3: (props) => <Typography variant="h3" gutterBottom sx={{ mt: 3, mb: 2 }} {...props} />,
  h4: (props) => <Typography variant="h4" gutterBottom {...props} />,
  p: (props) => <Typography variant="body1" sx={{ mb: 3 }} {...props} />,
  li: (props) => <Typography variant="body1" component="li" sx={{ mb: 2 }} {...props} />,
  a: (props) => <Link variant="body1" rel="noopener" {...props} />,
  img: (props) => <img {...props} style={{ maxWidth: '100%', ...(props.style || {}) }} />,
};

const AutolinkStyle = styled('div')`
  a.anchor {
    fill: ${({ theme }) => theme.palette.text.primary};
    height: 100%;
  }

  .caption {
    color: ${({ theme }) => theme.palette.text.secondary};
    text-align: center;
    margin-top: -10px;
  }

  .toc {
    p,
    li {
      margin-bottom: ${({ theme }) => theme.spacing(1)};
    }
  }
`;

export default ({ data: { mdx } }) => {
  const { pathname } = useLocation();
  return (
    <Layout>
      <AutolinkStyle>
        <Helmet title={mdx.frontmatter.title}>
          <meta name="description" content={mdx.frontmatter.description} />
          <link rel="canonical" href={`https://pollpilot.io${pathname}`} />
        </Helmet>
        <Container maxWidth="md">
          <Typography variant="body1" color="text.secondary" sx={{ mb: 1, mt: 15 }}>
            {mdx.frontmatter.date}
          </Typography>
          <Typography sx={{ mb: 6 }} variant="h1">
            {mdx.frontmatter.title}
          </Typography>
          <MDXProvider components={shortcodes}>
            <MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </Container>
        <Box mt={15} mb={15}>
          <FreeTrialCTA />
        </Box>
        <Box mt={15} mb={10}>
          <Widget id="pI_t6a2vjN0aqBA" />
        </Box>
      </AutolinkStyle>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        date
        description
      }
    }
  }
`;
